import { useState } from "react"

export const HomeState = () => {
    const [la, setLa] = useState({
        list:[]
    })
  return {
    la: {data: la, set: setLa}
  }
}
