import { TextField, styled } from "@mui/material";

export const CstmTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'red',
    },
    // '& .MuiInput-underline:after': {
    //     borderBottomColor: 'blue',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E8E8E8',
        },
        '&:hover fieldset': {
            borderColor: '#E8E8E8',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#008F5D',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        fontSize: '14px',
    },
});

export const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        fontSize: '14px',
    },
  });

  export const MSTextField = styled(TextField)({ //Mobiel search text field
    '& label.Mui-focused': {
        color: 'red',
    },
    // '& .MuiInput-underline:after': {
    //     borderBottomColor: 'blue',
    // },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#495F73',
        },
        backgroundColor: '#F7F7F8',
        borderRadius: '6px',
        fontSize: '12px',
    },
});

export const MCTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'red',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F7F7F8',
        },
        '&:hover fieldset': {
            borderColor: 'none',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F7F7F8',
        },
        backgroundColor: '#F7F7F8',
        borderRadius: '6px',
        fontSize: '16px',
        padding:'12px'
    },
});

export const DCTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'red',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'none',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F7F7F8',
        },
        '&:hover fieldset': {
            borderColor: '#E5EBF6',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#F7F7F8',
        },
        backgroundColor: '#F7F7F8',
        borderRadius: '6px',
        fontSize: '14px',
        padding:'10px'
    },
});
