import { Add, FormatAlignCenter, FormatAlignLeft, FormatAlignRight, FormatBold, FormatIndentIncrease, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, Height, InsertLink, Remove } from "@mui/icons-material"
import { Box, Divider, IconButton, InputBase, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"

export const TextEditor = ({editorRef, artcl}) => {
    const [fontSize, setFontSize] = useState(16);
    const [content, setContent] = useState("");

    useEffect(() => {
        if (editorRef.current) {
            const editorElement = editorRef.current;
            editorElement.innerHTML = artcl.data.form.inputs.description.value;
            const handleInput = () => {
                setContent(editorElement.innerText);
            };

            editorElement.addEventListener('input', handleInput);

            return () => {
                editorElement.removeEventListener('input', handleInput);
            };
        }
        // eslint-disable-next-line
    }, [editorRef]);
    
    const formatText = (command, value = null) => {
        document.execCommand(command, false, value);
    };

    // const addBlockquote = () => {
    //     const quote = prompt("Enter the quote");
    //     if (quote) {
    //         formatText("formatBlock", "<blockquote>");
    //         document.execCommand("insertHTML", false, quote);
    //     }
    // };

    const addLink = () => {
        const url = prompt("Enter the URL");
        if (url) {
          formatText("createLink", url);
        }
    };


    const fontSizeHandler = (size) => { 
        if (size >= 1 && size <= 200) {
            setFontSize(size);
            document.execCommand("fontSize", false, "7");
            const fontElements = document.querySelectorAll('font[size="7"]');
            fontElements.forEach((fontElement) => {
                fontElement.removeAttribute("size");
                fontElement.style.fontSize = `${size}px`;
            });
        }
    };

    const handleFontSizeChange = (event) => {
        const newSize = parseInt(event.target.value, 10);
        if (!isNaN(newSize) && newSize >= 1 && newSize <= 200) {
            fontSizeHandler(newSize);
        }
    };


    return (
        <Box width="100%" borderRadius="6px" border="1px solid #E7E8EA" p="16px">
            <Box display="flex" alignItems="center" gap={1}
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    paddingT: '8px',
                }}
            >
                <IconButton onClick={() => formatText("bold")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatBold />
                </IconButton>
                <IconButton onClick={() => formatText("italic")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatItalic />
                </IconButton>
                <IconButton onClick={() => formatText("underline")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatUnderlined />
                </IconButton>
                <Box display="flex" alignItems="center" gap="4px">
                    <IconButton onClick={() => fontSizeHandler(fontSize - 1)} sx={{ borderRadius: '6px', padding: '4px' }}>
                        <Remove sx={{ fontSize: '16px' }} />
                    </IconButton>
                    <Select
                        value={fontSize}
                        onChange={handleFontSizeChange}
                        input={<InputBase />}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                    marginTop: '4px',
                                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 60%)'
                                },
                            },
                        }}
                        sx={{
                            width: '60px',
                            textAlign: 'center',
                            border: '1px solid #E3E7EC',
                            borderRadius: '6px',
                            paddingLeft: '4px',
                            fontSize: '13px',
                            '& .MuiSelect-icon':{
                                padding:'4px'
                            }
                        }}
                    >
                        {[...Array(200).keys()].map(size => (
                            <MenuItem key={size + 1} value={size + 1} sx={{fontSize: '14px', padding: '4px 8px'}}>
                                {size + 1}
                            </MenuItem>
                        ))}
                    </Select>
                    <IconButton onClick={() => fontSizeHandler(fontSize + 1)} sx={{ borderRadius: '6px', padding: '4px' }}>
                        <Add sx={{ fontSize: '16px' }} />
                    </IconButton>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ mx: '16px' }} />
                <IconButton onClick={() => formatText("justifyLeft")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatAlignLeft />
                </IconButton>
                <IconButton onClick={() => formatText("justifyCenter")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatAlignCenter />
                </IconButton>
                <IconButton onClick={() => formatText("justifyRight")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatAlignRight />
                </IconButton>
                <Divider orientation="vertical" flexItem sx={{ mx: '16px' }} />
                <IconButton onClick={() => formatText("indent")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatIndentIncrease />
                </IconButton>
                <IconButton onClick={() => formatText("outdent")} sx={{ borderRadius: '6px', padding: '4px', transform: 'rotate(180deg)' }}>
                    <FormatIndentIncrease />
                </IconButton>
                <IconButton onClick={() => formatText("insertUnorderedList")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatListBulleted />
                </IconButton>
                <IconButton onClick={() => formatText("insertOrderedList")} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatListNumbered />
                </IconButton>
                {/* <IconButton onClick={addBlockquote} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <FormatQuote />
                </IconButton> */}
                <IconButton onClick={addLink} sx={{ borderRadius: '6px', padding: '4px' }}>
                    <InsertLink />
                </IconButton>
            </Box>
            <Divider sx={{ my: '16px' }} />
            <Box minHeight="300px" contentEditable ref={editorRef} sx={{ '&:focus': { outline: 'none' } }} />
        </Box>
    );
};
