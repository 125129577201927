import { useState } from 'react'

export const HomeState = () => {
    const [total, setTotal] = useState({article: [], posted: [],  saved: []})
    const [engagements, setEngagements] = useState({total: 0, graph: {y: [], x: []}})
    
  return {
    total: {data: total, set: setTotal}
    
  }
}
