import React, { useState } from "react";
import { Close } from "@mui/icons-material"
import { Box, Button, Dialog, IconButton, MenuItem, Zoom } from "@mui/material"
import { CstmTextField } from "../../../../../core/global/TextField"
import Slide from '@mui/material/Slide';
import { S3 } from "../../../../../core/transmit/S3";
import { rqx } from "../../../../../core/request/API";
import { env } from "../../../../../core/Env";
import { useHistory } from 'react-router-dom'
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export const Categories = ({artcl, changeHandler, uid, typeParams}) => {
    const [isLoad, setisLoad] = useState(false);

    const history = useHistory();
    const disable = artcl.data.form.inputs.title.value === '' || artcl.data.form.inputs.description.value === '' || artcl.data.form.inputs.category.value === '' || artcl.data.form.inputs.taxType.value === '' || artcl.data.form.inputs.reference.value === '' || artcl.data.form.inputs.file.object === null ? true : false

    const backHandler = () => {
        artcl.set({...artcl.data, form:{...artcl.data.form, toggled: false}})
    }

    const submitHandler = async () => {
        setisLoad(true)

        let urlS3;
        if (artcl.data.form.inputs.file.value !== null) {
            urlS3 = await S3(artcl.data.form.inputs.file.value)
        }

        const reqBody = {
            company_id: 1,
            article_category_id: artcl.data.form.inputs.category.value,
            article_tax_type_id: artcl.data.form.inputs.taxType.value,
            title: artcl.data.form.inputs.title.value,
            description: artcl.data.form.inputs.description.value,
            reference: artcl.data.form.inputs.reference.value,
            id: artcl.data.form.inputs.id.value,
            user_id: uid,
            status: typeParams === 'create' ? 2 : artcl.data.form.inputs.status.value,
            file_link: artcl.data.form.inputs.file.value !== null ? urlS3.location : artcl.data.form.inputs.file.object,
            file_size: artcl.data.form.inputs.file.size,
            file_type: artcl.data.form.inputs.file.type,
        }
        
        let data = await rqx.post(`<<tool>>/${env()}/library/article/${typeParams}`, reqBody)
        if (data.message === 'success') {
            artcl.set({...artcl.data, 
                form:{...artcl.data.form, 
                    inputs:{...artcl.data.form.inputs, 
                        file:{...artcl.data.form.inputs.file, value: null, object: null, name: '', type: '', size: 0, updated: false, stat: false, msg: ''},
                        title: {...artcl.data.form.inputs.title, value: '', stat: true, msg: ''},
                        description: {...artcl.data.form.inputs.description, value: '', stat: true, msg: ''},
                        category: {...artcl.data.form.inputs.category, value: '', stat: true, msg: '', list: []},
                        taxType: {...artcl.data.form.inputs.taxType, value: '', stat: true, msg: '', list: []},
                        reference: {...artcl.data.form.inputs.reference, value: '', stat: true, msg: ''},
                        pname: {...artcl.data.form.inputs.pname, value: '', stat: true, msg: ''},
                        seriesNum: {...artcl.data.form.inputs.seriesNum, value: '', stat: true, msg: ''},
                        status: {...artcl.data.form.inputs.status, value: 2, stat: true, msg: ''},
                        id: {...artcl.data.form.inputs.id, value: '', stat: true, msg: ''},
                        static_series: {...artcl.data.form.inputs.static_series, value: '', stat: true, msg: ''},
                    },
                    toggled: false
                },
                alert: {
                    toggled: true,
                    type: 'success',
                    msg:  `${typeParams === 'create' ? 'Successfully Posted' : 'Successfully Updated'}`,
                },
            })
            history.push('/biz/library')
        }
    }

    return (
        <Dialog open={artcl.data.form.toggled} fullScreen TransitionComponent={Transition}>
            <Box width="100%" height="100%" display="flex" justifyContent="center">
                <Box width="592px" height="100%" display="flex" justifyContent="center" flexDirection="column">
                    <Box width="100%" display="flex" justifyContent="flex-end" alignItems="flex-start">
                        <IconButton onClick={() => backHandler()}>
                            <Close/>
                        </IconButton>
                    </Box>
                    <Box fontSize="14px" color="283745" mt="24px" mb="8px">Category</Box>    
                    <CstmTextField fullWidth select name="category" value={artcl.data.form.inputs.category.value} onChange={(e) => changeHandler(e)}>
                        {artcl.data.form.inputs.category.list.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}   
                            </MenuItem>
                        ))}
                    </CstmTextField>
                    <Box fontSize="14px" color="283745" mt="24px" mb="8px">Tax type</Box>    
                    <CstmTextField fullWidth select name="taxType" value={artcl.data.form.inputs.taxType.value} onChange={(e) => changeHandler(e)}>
                        {artcl.data.form.inputs.taxType.list.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </CstmTextField>
                    <Box fontSize="14px" color="283745" mt="24px" mb="8px">Reference</Box>    
                    <CstmTextField fullWidth name="reference" value={artcl.data.form.inputs.reference.value} onChange={(e) => changeHandler(e)}/>
                    <Box width="100%" mt="60px" display="flex" justifyContent="center">
                        {
                            isLoad ? 
                                <Button variant="contained" sx={{height: '36px'}} disableElevation>
                                    <SnakeLoader size="10px" bg="#FFFFFF" distance="0.2rem" />
                                </Button>
                            :
                                <Button onClick={submitHandler} disabled={disable} variant="contained" disableElevation sx={{fontSize:'14px', background:'#295F47', padding:'8px 16px',borderRadius:'6px'}}>
                                    Publish
                                </Button>
                        }
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}