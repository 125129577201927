// Libraries
import { useMediaQuery } from '@mui/material';

// Assets
import '../assets/css/global.css';
import '../assets/css/scrollbar.css';
import pslLogo from '../assets/images/logos/ptx_psl_logo.png'
import bizLogo from '../assets/images/logos/ptx_biz_logo.png'
import pslBanner from '../assets/images/core/pslmain.png'
import bizBanner from '../assets/images/core/bizmain.png'

// Layouts
import { Box } from '@mui/system';

export const Index = () => {

	const m = useMediaQuery('(max-width:1150px) and (min-width:0px)')
	const dis = "It is a digital tax tool that provides electronic data collection, real-time on tax laws and tax cases files that Aids taxpayers, professionals and government in efficiently anaging tax compliance to achieve the Extraordinary and heroic outcome in combating fraud in the Philippines."
	
	const cHandler = (type) => {
		window.location.href = `${type}`
	}

    return (
		<>
			<Box height={m ? "100%" : "100vh"} display="flex" flexDirection="column" fontFamily="Inter, sans-serif" py="">
				<Box height={m ? "100%" : "35vh"} p={m && "56px 24px"} display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="24px" >
					<Box fontSize="48px" fontWeight="700" lineHeight="59.52px" color="#292C2F">Philtaxes</Box>
					<Box maxWidth="700px" color="#292C2F" textAlign="center" fontWeight="600" whiteSpace="pre-wrap">{dis}</Box>
					<Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap" gap="24px">
						<Box onClick={() => cHandler('psl')} p="12px 56px" bgcolor="#0073CF" borderRadius="6px" color="#fff" fontSize="14px" lineHeight="19.88px" sx={{cursor:'pointer', '&:hover':{background:'#005AA3'}}}>
							Philtaxes Personal
						</Box>
						<Box onClick={() => cHandler('biz')} p="12px 56px" bgcolor="#003022" borderRadius="6px" color="#fff" fontSize="14px" lineHeight="19.88px" sx={{cursor:'pointer', '&:hover':{background:'#001711'}}}>
							Philtaxes Business
						</Box>
					</Box>
				</Box>
				<Box width="100%" display="flex" flexDirection="row" flexWrap="wrap">
					<Box width={m ? "100%" : "50%"} height={m ? "auto" : "60vh"} bgcolor="#E3F2FD" p={m && "24px"}>
						<img src={pslLogo} alt="pslLogo" style={{height:'40px', width:'40px', objectFit:'contain', padding:m ? '0px' : '40px'}} />
						<Box display="flex" alignItems="center" justifyContent="center" px={m && '24px'}>
							<img src={pslBanner} alt="pslBanner" style={{height:'100%', maxHeight:'348px', width:'100%', maxWidth:'574px', objectFit:'contain'}} />
						</Box>
					</Box >
					<Box width={m ? "100%" : "50%"} height={m ? "auto" : "60vh"} bgcolor="#DAE8DA" p={m && "24px"}>
						<img src={bizLogo} alt="bizLogo" style={{height:'40px', width:'40px', objectFit:'contain', padding:m ? '0px' : '40px'}} />
						<Box display="flex" alignItems="center" justifyContent="center" px={m && '24px'}>
							<img src={bizBanner} alt="bizBanner" style={{height:'100%', maxHeight:'348px', width:'100%', maxWidth:'574px', objectFit:'contain'}} />
						</Box>
					</Box>
				</Box>
				<Box height={m ? "200px" : "5vh"} display="flex" alignItems="center" justifyContent="center">
					<Box fontSize="12px" color="#292C2F" py={m && "24px"}> 
						© 2024 Philtaxes. All Rights Reserved.
					</Box>
				</Box>
			</Box>
		</>
    )
}
