import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';

import { Index as ToolCategories } from '../../__personal/components/toolcategories/Index';
import { RenderAPI } from '../../__personal/components/global/RenderAPI';
import { Switching } from '../../__personal/components/swtiching/Index'
import { Settings } from '../../__personal/components/settings/Index';
import { Result } from '../../__personal/components/search/devices/mobile/Result'
import { Recent } from '../../__personal/components/profile/devices/mobile/Recent'
import TestComponent from '../../__personal/components/TestComponent';

let view = VM();
const Navigation = lazy(() => import(`../../__personal/components/layouts/navigations${view}/Index`))
const Home = lazy(() => import (`../../__personal/components/home${view}/Index`))
const Library = lazy(() => import (`../../__personal/components/library${view}/Index`))
const Article = lazy(() => import (`../../__personal/components/library/article${view}/Index`))
const Profile = lazy(() => import (`../../__personal/components/profile${view}/Profile`))
const Search = lazy(() => import (`../../__personal/components/search${view}/Index`))
const Bookmark = lazy(() => import (`../../__personal/components/profile/bookmark${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;
        
    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/psl/switching/:params" render={props=><Switching {...props}/>} />
            <Route exact path="/psl" render={props=>(Render(Home, props, location))}/>
            <Route exact path="/psl/more" render={props=>(Render(Settings, props, location))} />
            <Route exact path="/psl/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            <Route exact path="/psl/library" render={props => (Render(Library, props, location))} />
            <Route exact path="/psl/article" render={props => (Render(Article, props, location))} />

            <Route exact path="/psl/search" render={props => (Render(Search, props, location))} />
            <Route exact path="/psl/search/result" render={props => (Render(Result, props, location))} />

            <Route exact path="/psl/profile" render={props => (Render(Profile, props, location))} />
            <Route exact path="/psl/profile/recent" render={props => (Render(Recent, props, location))} />
            <Route exact path="/psl/profile/bookmark" render={props => (Render(Bookmark, props, location))} />
            <Route exact path="/psl/test/:params" render={props => (Render(TestComponent, props, location))} />

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props, location) => (
    
    <>
        {view === '' ? (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {(location !== '/psl/profiling' && location.split(/\/(\d+)&(.+)/).slice(0, 1)[0] !== '/psl/article') &&  (<Navigation side={1} />)}
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        {(location.split(/\/(\d+)&(.+)/).slice(0, 1)[0] !== '/psl/article') && ( <Navigation side={0} />)}
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {
                        (
                            location !== '/psl/profiling' && 
                            location !== '/psl/more' && 
                            location.split(/\/(\d+)&(.+)/).slice(0, 1)[0] !== '/psl/article' &&
                            location !== '/psl/search' &&
                            location !== '/psl/search/result' &&
                            location.split('/')[2] !== 'profile' 
                        ) 
                            && 
                        (
                            <Box minHeight={location === '/psl' ? '92px' : '40px'}>
                                <Navigation side={0} />
                            </Box>
                        )
                    }
                    <Box height="100%" width="100%" className="overflowY noScrollcss"><Component {...props} /></Box>
                    {
                        (
                            location !== '/profile' && 
                            location !== '/psl/profiling' && 
                            location.split(/\/(\d+)&(.+)/).slice(0, 1)[0] !== '/psl/article' &&
                            location !== '/psl/search' &&
                            location !== '/psl/search/result' &&
                            location.split('/')[2] !== 'profile' 
                        ) 
                    &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        )}
        <RenderAPI/>
    </>
)