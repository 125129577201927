// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

import { Index as IndexBusiness } from './__business'
import { Index as IndexPersonal } from './__personal'
import { Index as IndexMain } from './__main/Index'

const loc = window.location.pathname
const subdir = loc.split('/')[1]
let Core

if (subdir === 'psl') {
	Core = IndexPersonal
} else if(subdir === 'biz'){
	Core = IndexBusiness
} else{
	Core = IndexMain
	console.log('Philtaxes Version V1.1.6')
	
	// Core = IndexBusiness
	// Core = IndexPersonal
}

ReactDOM.render(
<Core/>, document.getElementById('root'));		