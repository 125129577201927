import { Box, Button, IconButton } from "@mui/material";
import { ArrowLeftIcons } from "../../../../../core/global/Icons";
import { CstmTextField } from "../../../../../core/global/TextField";
import { Thumbnail } from "./Thumbnail";
import { useContext, useRef, useState } from "react";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { TextEditor } from "./TextEditor";
import { Categories } from "./Categgories";
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from "react";
import { env } from "../../../../../core/Env";
import { rqx } from "../../../../../core/request/API";

export const ArticleForm = () => {
    const { library_state, tool_state } = useContext(ToolContext)
	const { artcl } = library_state
    const { __SESSION } = tool_state

    const uid = window.atob(__SESSION.data.ainfo.aid).slice(8)
    const editorRef = useRef(null);
    const history = useHistory();
    const typeParams = new URLSearchParams(useLocation().search).get('type')
 

    const nextStepHandler = () => {
        artcl.set({...artcl.data, form:{...artcl.data.form, toggled: true, inputs:{...artcl.data.form.inputs, description:{...artcl.data.form.inputs.description, value: editorRef.current.innerHTML}}}})
    }

    const _fetchArticleData = async () => {
        let ctrgy = await rqx.get(`<<tool>>/${env()}/library/article/articleCategory`)
        let ttyp = await rqx.get(`<<tool>>/${env()}/library/article/taxType`)

        artcl.set({...artcl.data, form:{...artcl.data.form, inputs:{...artcl.data.form.inputs, category:{...artcl.data.form.inputs.category, list: ctrgy}, taxType:{...artcl.data.form.inputs.taxType, list: ttyp}}}})

    }

    useEffect(() => {
        _fetchArticleData()
        if(typeParams === 'update'){
            if ((typeParams !== 'create' && typeParams !== 'update') || (artcl.data.form.inputs.pname.value === '' && artcl.data.form.inputs.title.value === '' && artcl.data.form.inputs.description.value === '' && artcl.data.form.inputs.category.value === '' && artcl.data.form.inputs.taxType.value === '' && artcl.data.form.inputs.reference.value === '' && artcl.data.form.inputs.file.object === null)) return history.push('/biz/library')
        } else {
            if (typeParams !== 'create' && typeParams !== 'update') return history.push('/biz/library')
        }
        
        // eslint-disable-next-line
    }, [])

    const changeHandler = (e) => {
        const {name, value} = e.target
        if (value[0] === ' ') return
        artcl.set({...artcl.data, form:{...artcl.data.form, inputs:{...artcl.data.form.inputs, [name]:{...artcl.data.form.inputs[name], value: value}}}})
    }

    const closeHandler = () => {
        artcl.set({...artcl.data, 
            form:{...artcl.data.form, 
                inputs:{...artcl.data.form.inputs, 
                    file:{...artcl.data.form.inputs.file, value: null, object: null, name: '', type: '', size: 0, updated: false, stat: false, msg: ''},
                    title: {...artcl.data.form.inputs.title, value: '', stat: true, msg: ''},
                    description: {...artcl.data.form.inputs.description, value: '', stat: true, msg: ''},
                    category: {...artcl.data.form.inputs.category, value: '', stat: true, msg: '', list: []},
                    taxType: {...artcl.data.form.inputs.taxType, value: '', stat: true, msg: '', list: []},
                    reference: {...artcl.data.form.inputs.reference, value: '', stat: true, msg: ''},
                    pname: {...artcl.data.form.inputs.pname, value: '', stat: true, msg: ''},
                    seriesNum: {...artcl.data.form.inputs.seriesNum, value: '', stat: true, msg: ''},
                    status: {...artcl.data.form.inputs.status, value: 2, stat: true, msg: ''},
                    id: {...artcl.data.form.inputs.id, value: '', stat: true, msg: ''},
                    static_series: {...artcl.data.form.inputs.static_series, value: '', stat: true, msg: ''},
                }
            },
        })
        history.push('/biz/library')
    }

    return (
        <Box width="100%" height="100%" bgcolor="#FFFFFF">
            <Categories artcl={artcl} changeHandler={changeHandler} uid={uid} typeParams={typeParams}/>
            <Box width="100%" height="100%" display="flex" alignItems="center" flexDirection="column">
                <Box width="1048px" display="flex" alignItems="center" p="24px 16px">
                    <Box width="100%" display="flex" justifyContent="left">
                        <IconButton onClick={() => closeHandler()} sx={{borderRadius:'6px'}}>
                            <ArrowLeftIcons width={24} height={24}/>
                        </IconButton>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="center" fontSize="14px" fontWeight="bold" color="#5B6167">
                        {typeParams === 'create' ? 'Create' : typeParams === 'update' ? 'Update' : ''} Article
                    </Box>
                    <Box width="100%" display="flex" justifyContent="right">
                        <Button onClick={() => nextStepHandler()} variant="contained" disableElevation sx={{fontSize:'14px', background:'#295F47', padding:'4px 16px',borderRadius:'6px'}}>
                            Next
                        </Button>
                    </Box>
                </Box>
                <Box width="756px" height="100%" p="30px 16px" display="flex" flexDirection="column" gap="24px" overflow="auto" className="noScrollcss">
                    <Box>
                        <Box fontSize="14px" color="#5B6167" mb="8px">Title *</Box>    
                        <CstmTextField fullWidth name="title" value={artcl.data.form.inputs.title.value} onChange={(e) => changeHandler(e)}/>
                    </Box>
                    <Thumbnail artcl={artcl}/>
                    <Box>
                        <Box fontSize="14px" color="#5B6167" mb="8px">Discreption</Box>    
                        <TextEditor editorRef={editorRef} artcl={artcl}/>
                    </Box>
                </Box>
            </Box>
        </Box> 
    );
};
