import { Box } from "@mui/material"
import { useParams, useHistory } from 'react-router-dom';

const TestComponent = () => {
    const { params } = useParams();
    console.log(params);
    return (
        <Box>asdasdf</Box>  
    )
}

export default TestComponent